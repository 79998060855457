<template lang="pug">
	b-modal#modalwithdraw.withdraw(ref='modal' :centered="!isMobile" :scrollable="isMobile" content-class="modal-max-content" header-class="d-flex p-0 border-0 align-items-center mb-3" body-class="p-0 d-flex flex-column" hide-footer)
		template(#modal-header="{ close }")
			h3(v-if='card') {{ $t('finance.withdrawRequest') }}
			icon-close.withdraw__close(@click="close()")

		.d-flex.flex-column(v-if='card && card.hasWithdrawal()')
			p.b3.mb-4.disclaimer.warning(v-html='withdrawalSumDesc')
			p.b3.mb-4 {{ $t('finance.cardHasWithdrawalDesc2') }}
			.btn.btn-primary.mt-auto(@click='close') {{$t('social.understand')}}
		.d-flex.flex-column(v-else-if='card && !card.canWithdrawal()')
			p.b3.mb-4.disclaimer.warning(v-html='minWithdrawalTitle')
			.btn.btn-primary.mt-auto(@click='close') {{$t('social.understand')}}

		div(v-else-if='card')
			div.withdraw__step(v-if='canSelectAmountForWithdrawal && step == 0')
				//- p.b3.mb-4.disclaimer.error {{ $t('finance.withdrawal.minSumError') }}
				my-input.mb-4(
					v-model="amount"
					:type="'number'"
					:label="$t('forms.sum')"
					:icon="'icon-money'"
					:errorFeedback="amountError ? amountError : ''"
					@blur="checkAmount"
				)
				.btn.btn-primary.mt-auto(@click='step = 1' :class="{'disabled': amountError || !amount}") {{$t('wizard.continue')}}
			div.withdraw__step(v-else)
				p.b3.mb-2 {{ $t('finance.withdraw.checkDetails') }}


				.d-flex.align-items-center.withdraw__item
					span.b3.text-grey.col-md-6.p-0.mb-2.mb-md-0 {{ $t('forms.placeholder.companyShortName') }}
					span.col-md-6.p-0 {{ card.shop.shortName }}
				.d-flex.align-items-center.withdraw__item
					span.b3.text-grey.col-md-6.p-0.mb-2.mb-md-0 {{ $t('forms.accountNumber2') }}
					span.col-md-6.p-0 {{ card.shop.bankAccountNumber }}
				.d-flex.align-items-center.withdraw__item
					span.b3.text-grey.col-md-6.p-0.mb-2.mb-md-0 {{ $t('forms.sum') }}
					span.col-md-6.p-0(v-html='`${amount} ${card.available.currencyTitle()}`')

				//- p.b3.my-4.disclaimer.error {{ descInnError }}

				p.b3.text-grey.border-rounded.my-3.withdraw__converted {{ $t('finance.withdrawal.desc') }}
				b-form-checkbox.b3.mb-3(id="confirm-amount" v-model="confirm" name="confirm-amount" :value="true" :unchecked-value="false") {{ $t('finance.withdrawal.confirm') }}
				.btn.btn-primary.w-100.mt-auto(@click='send' :class="{'disabled': isPending || !confirm}") {{$t('finance.doWithdrawalLong')}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { MIN_SUM_WITHDRAWAL } from '@/models/account';
import IconClose from '@/components/Common/IconClose';
import MyInput from '@/components/UI/MyInput';

export default {
    name: 'WithdrawModal',
    components: {
        IconClose,
        MyInput,
    },
    data: () => ({
        confirm: false,
        step: 0,
        amount: null,
        amountError: null,
        inn: 123456789,
    }),
    methods: {
        ...mapActions({
            add: 'finance/addWithdrawal',
        }),
        send() {
            let params = { id: this.card.id };
            params.payout = Number(this.card.available.amount * 100).toFixed(0);

            if (this.canSelectAmountForWithdrawal) params.payout = Number(this.amount * 100).toFixed(0);

            this.add(params)
                .then(c => {
                    this.card.madeWithdrawal(Number(params.payout), c);
                    this.close();
                    this.$emit('openWithdrawCreated');
                })
                .catch(c => {
                    this.$notify('error', c);
                });
        },
        checkAmount() {
            this.amountError = null;

            if (this.amount == 0 || this.amount == null || this.amount.trim() == '')
                return (this.amountError = this.$t('promotions.form.error'));
            if (this.amount.match(/^\d*(\.\d{1,2})?$/) == null)
                return (this.amountError = this.$t('errors.numeric.amount'));
            if (
                this.amount < this.card.minSubForWithdrawal() ||
                Number(this.amount) > Number(this.card.available.price())
            )
                return (this.amountError = this.$t('finance.withdrawal.minSumError').replace(
                    '%sum%',
                    this.card.minSubForWithdrawal() + ' ' + this.card.available.currencyTitle()
                ));
        },
        close() {
            this.$refs.modal.hide();
        },
    },
    computed: {
        ...mapGetters({
            isPending: 'finance/isPending',
            card: 'modals/withdrawal',
        }),
        withdrawalSumDesc() {
            if (!this.card || !this.card.hasWithdrawal()) return '';

            if (this.card.requestedWithdrawals[0] && this.card.requestedWithdrawals[0].price)
                return this.$t('finance.cardHasWithdrawalDesc').replace(
                    '%sum%',
                    `<span class='font-weight-medium'>${this.card.requestedWithdrawals[0].price.priceWithCurrency()}</span>`
                );

            return this.$t('finance.cardHasWithdrawalDesc').replace('%sum%', '');
        },
        descInnError() {
            return this.$t('finance.withdrawal.innError').replace('%inn%', this.inn);
        },

        minWithdrawalTitle() {
            return this.$t('finance.cardCantWithdrawalDesc').replace(
                '%sum%',
                this.card.minSubForWithdrawal() + ' ' + this.card.available.currencyTitle()
            );
        },

        canSelectAmountForWithdrawal() {
            return (
                (this.me.isMerchant || this.me.isMerchantPartner) &&
                this.card &&
                (this.card.isOrganization() || (this.card.isStripe() && this.card.canWithdrawal()))
            );
        },
    },
    watch: {
        card: {
            handler(nV, oV) {
                if (nV != null) {
                    this.step = 0;

                    if (this.card != null && this.card.available) this.amount = this.card.available.price();
                    return this.$refs.modal.show();
                }

                if (this.$refs.modal) this.$refs.modal.hide();
            },
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.withdraw {
    &__step {
        width: 470px;

        @include respond-below(sm) {
            width: 100%;
        }
    }

    &__btn-understand {
        min-width: 301px;
    }

    &__item {
        padding: 12px 0;
        border-bottom: 1px solid var(--border-line);

        @include respond-below(sm) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    &__converted {
        padding: 20px;
        background-color: var(--bg-back);

        @include respond-below(sm) {
            padding: 15px;
        }
    }

    &__close {
        transition: var(--animation-time-short);

        &:hover,
        &:active {
            opacity: 0.7;
        }
    }
}

#modalwithdraw {
    .modal-body {
        @include respond-below(sm) {
            /* Скрываем scrollbar для IE, Edge и Firefox */
            -ms-overflow-style: none; /* IE и Edge */
            scrollbar-width: none; /* Firefox */

            /* Скрываем scrollbar для Chrome, Safari и Opera */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
</style>
